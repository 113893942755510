// extracted by mini-css-extract-plugin
export var buttons = "Hero-module--buttons--43166";
export var container = "Hero-module--container--4537c";
export var haibike = "Hero-module--haibike--b79af";
export var haibikeKid = "Hero-module--haibikeKid--19f64";
export var left = "Hero-module--left--9f837";
export var leftButton = "Hero-module--leftButton--9e8d5";
export var right = "Hero-module--right--4fb64";
export var shape = "Hero-module--shape--41f0a";
export var text = "Hero-module--text--c0bf8";
export var textRow = "Hero-module--textRow--b4785";
export var title = "Hero-module--title--b7d95";