import React from 'react';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from '@components/Button/Button';

import * as styles from './Hero.module.css';

const Hero = ({ en }) => {
  const onBookClick = () => {
    navigate(!en ? '/ebike' : '/en/ebike');
  };

  const onContactClick = () => {
    if (typeof window !== 'undefined') {
      window.location = 'mailto:info@tizianobiellersport.com';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <StaticImage
          src="../../static/img/ebike/haibike-transparent.webp"
          className={styles.haibike}
          alt={!en ? 'E-Bike Adulto' : 'E-Bike Adult'}
          placeholder="blurred"
          width={554}
          height={369}
        />
        <StaticImage
          src="../../static/img/ebike/haibike-bambino-transparent.webp"
          className={styles.haibikeKid}
          alt={!en ? 'E-Bike Bambino' : 'E-Bike Kids'}
          placeholder="blurred"
          width={554}
          height={369}
        />
        <div className={styles.shape}></div>
      </div>
      <div className={styles.right}>
        <h1 className={styles.title}>
          {!en ? 'Noleggio E-Bike Champoluc' : 'E-Bike Rental Champoluc'}
        </h1>
        <p className={styles.text}>
          <span className={styles.textRow}>
            {!en
              ? 'Bici a pedalata assistita per bambini e adulti con varie modalita di noleggio!'
              : 'Electric bikes for children and adults with various rental options!'}
          </span>
          <span className={styles.textRow}>
            {!en
              ? 'Un modo sportivo, ecologico e adatto a tutti per vivere le nostre montagne.'
              : 'A sporty, ecological way suitable for everyone to experience our mountains.'}
          </span>
        </p>
        <div className={styles.buttons}>
          <Button onClick={onBookClick} className={styles.leftButton}>
            {!en ? 'SCOPRI DI PIÙ' : 'DISCOVER MORE'}
          </Button>
          <Button type="secondary" onClick={onContactClick}>
            {!en ? 'CONTATTACI' : 'CONTACT US'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
